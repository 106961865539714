.connect-page-wrapper {
  height: 80vh;
  width: 100%;
}

.connect-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/water_tiny.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: none;
  display: relative;
  opacity: 0.9;
  height: 100%;
}

.connect-form-container {
  margin: 70px;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px -8px;
  width: 400px;
}

.connect-form {
  display: flex;
  flex-direction: column;
}

.contact-me-title {
  color: #808080;
  font-size: 2em;
  margin-bottom: 20px;
}

.connect-input {
  border: none;
  border-bottom: 2px solid #e4e3e3;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
}

.connect-comment {
  resize: none;
  height: 180px;
  margin-bottom: 30px;
  font-family: "Rubik Light";
}

.submit-button {
  border-color: #86adc2;
  padding: 10px;
  border-radius: 5px;
  color: #86adc2;
  font-size: 18px;
  font-family: "Rubik Light";
  background-color: white;
  cursor: pointer;
}

.submit-button:hover {
  color: #1a3442;
  border-color: #1a3442;
}

.error-x {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.connect-error-message {
  font-size: 14px;
  border: 2px solid red;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff4f4;
  color: red;
  position: relative;
}
