.home-breathwork-wrapper {
  width: 100%;
  margin-top: 15px;
  padding-top: 25px;
  background-color: #ecedf070;
}

.wrapper-container {
  background-image: url("https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/swing_background.jpeg");
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.text-container {
  height: inherit;
  margin: 30px;
  padding: 30px;
  width: 400px;
  border: 3px solid white;
  border-radius: 10px;
  opacity: 0.7;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  line-height: 1.2em;
  font-weight: normal;
}

.breathwork-paragraph {
  font-size: 18px;
  letter-spacing: 1.1px;
  line-height: 1.2em;
  padding-bottom: 15px;
}

.join-button {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 20px;
  width: fit-content;
}

.join-button:hover {
  cursor: pointer;
  color: #336884;
  border-color: #336884;
}

.title {
  opacity: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: normal;
}

.testimonial-carousel-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
}

.testimonial-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 100px;
}

.quote-icon {
  display: flex;
  position: absolute;
  top: 70px;
  left: 70px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.slide {
  background-color: white !important;
}

.dot {
  background-color: #336884 !important;
}

.control-dots {
  position: inherit !important;
}

.quote-name {
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
  padding-top: 20px;
}

.slider-container {
  padding: 5vw;
  line-height: 1.3;
  letter-spacing: 1.1px;
  width: 100%;
  box-sizing: border-box;
}

.event-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.event-detail-info-container {
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.05em;
  line-height: 1.4em;
}

.event-detail-container {
  width: 40vw;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.event-details-link {
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

/* .event-details-link:hover h2 {
  color: #4283a5;
}

.event-details-link:hover h4 {
  color: #4283a5;
} */

.link-text-button {
  padding-top: 15px;
  text-transform: uppercase;
  cursor: pointer;
  height: 25px;
}

.event-details-link:hover .link-text-button {
  color: #4283a5;
  cursor: pointer;
  font-size: 20px;
}

.home-offerings-container {
  width: 100%;
  padding-top: 25px;
  background-color: #ecedf070;
}

.offerings-list-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.custom-offering-item-container {
  padding: 20px;
  margin: 30px;
  width: 35vw;
  border: 1px solid black;
  background-color: white;
}

.line {
  height: 1px;
  border-bottom: 1px solid grey;
  margin-left: 40px;
  margin-right: 40px;
}

.offering-info-container {
  padding: 20px;
  letter-spacing: 1.1px;
}

.offering-info-line {
  padding-bottom: 10px;
}

.custom-offering-item-container:hover {
  /* color: #4283a5; */
  cursor: pointer;
  border: 1px solid #4283a5;
}

.custom-offering-item-container:hover h3 {
  color: #4283a5;
}

.offering-info-paragraph {
  margin-top: 20px;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  overflow: hidden;
  max-height: 0;
}

.custom-offering-item-container:hover .offering-info-paragraph {
  max-height: 300px;
}

@media screen and (max-width: 650px) {
  .event-details-wrapper {
    flex-direction: column;
  }

  .event-detail-container {
    width: 80vw;
  }

  .custom-offering-item-container {
    width: 60vw;
  }
}
