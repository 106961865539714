.meditate-body {
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.tabs {
  width: fit-content;
  display: flex;
  margin-bottom: 30px;
}

.tab {
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid #e4e3e3;
  text-transform: uppercase;
  color: #adadad;
  cursor: pointer;
}

.breathwork-with-lo {
  color: #808080;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.active-tab {
  border-bottom: 5px solid #1a3442;
  color: #1a3442;
}

.tab-sections {
  width: fit-content;
  display: flex;
  margin-bottom: 30px;
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tab-section {
  height: 100%;
  width: 100%;
}

.active-section {
  visibility: visible;
  display: inline;
}

.hidden-section {
  visibility: hidden;
  display: none;
}

.paragraph {
  margin-bottom: 20px;
  /* overflow-wrap: break-word; */
  line-height: 1.4;
  letter-spacing: 0.3px;
  /* text-align: start; */
  display: flex;
  justify-content: center;
}

.private-description {
  overflow-wrap: break-word;
  line-height: 1.4;
  letter-spacing: 0.3px;
  text-align: start;
  width: 70vw;
  max-width: 700px;
}

.private-description-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.breathwork-is-for-you-container {
  text-align: left;
  width: 70vw;
  max-width: 700px;
}

@media screen and (max-width: 750px) {
  .tab-sections {
    width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .tab-sections {
    width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .tab-sections {
    width: inherit;
    height: inherit;
  }

  .tabs {
    display: flex;
    flex-direction: column;
  }
}
