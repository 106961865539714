.image-clouds {
  width: 100%;
  height: 55vh;
  background-image: url("https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/Clouds.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: none;
  display: relative;
}

.count-down {
  font-size: large;
  color: white;
  font-weight: bold;
  padding: 20px;
  padding-top: 30px;
  color: #f5f5ef;
}

.header-text {
  /* color: #1a3442; */
  color: white;
  font-weight: bold;
  font-size: 38px;
  margin-bottom: 20px;
  letter-spacing: 1.1px;
  font-family: "Poppins", sans-serif;
  text-shadow: 1px 1px 1px rgba(72, 75, 77, 0.77);
}

.mini-header-text {
  max-width: 70vw;
  letter-spacing: 1.1px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cloud-text-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.breathe-now-button {
  padding: 15px;
  color: #f5f5ef;
  font-weight: bold;
  font-size: 20px;
  border: 2px solid #f5f5ef;
  /* font-family: "Poppins", sans-serif; */
}

.five-days-section {
  background-color: #1a3442;
  min-height: 400px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 1;
}

.five-days-section-side {
  width: 50%;
  color: #1a3442;
}

.beige-background-color {
  background-color: #efeae5;
  padding: 30px;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
}

.five-days-section-side-photo {
  background-image: url(https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/grand_teton+copy.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: none;
  display: relative;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.lo-image-container {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
}

.lo-image {
  max-width: 530px;
  background-position: top;
  background-image: url(https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/lo-mystic.JPG);
}

.line-separator {
  border-bottom: 2px solid #1a3442;
  width: 100%;
}

.five-days-text-container {
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  /* font-family: "Belotta-Regular"; */
  /* font-family: "PlayfairDisplay"; */
}

.five-days-text-date {
  padding-top: 30px;
  padding-bottom: 30px;
}

.five-days-section-title {
  font-size: 40px;
  padding-bottom: 30px;
  font-weight: medium;
  font-family: "Poppins";
}

.five-days-paragraph {
  padding-bottom: 20px;
  line-height: 1.3em;
  letter-spacing: 1.1px;
}

.quote-section {
  font-family: "Poppins", sans-serif;
  font-size: large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  padding-bottom: 30px;
  padding-top: 30px;
  line-height: 1.3em;
  letter-spacing: 1.1px;
}

.five-days-quote {
  padding-bottom: 15px;
}

.five-days-quote-by {
  font-size: large;
}

.blue {
  color: #1a3442;
  border-color: #1a3442;
}

@media screen and (max-width: 770px) {
  .five-days-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .five-days-section-side {
    min-height: 400px;
    margin-bottom: 20px;
    width: 75%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .five-days-section-side-photo {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .five-days-section-side-photo {
    height: 400px;
    /* width: 60%; */
    margin-top: 20px;
  }

  .lo-image {
    /* min-height: 400px; */
    width: inherit;
    margin-top: 20px;
  }
}
