.App {
  text-align: center;
  background-color: white;
  position: relative;
  height: 100%;
}

.home-page {
  position: relative;
}
/* 
.sign-up-button {
  color: white;
  width: 75px;
  letter-spacing: 1.5px;
  background-color: #AEBDA2;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
} */

.email {
  color: #1a3442;
}

.sign-up-button {
  color: #AEBDA2;
  width: 75px;
  letter-spacing: 1.5px;
  border: 1px solid #AEBDA2;
  text-transform: uppercase;
  padding: 10px;
  /* border-radius: 5px; */
  text-decoration: none;
  font-weight: bold;
}

.sign-up-button:hover {
  color: #5A6958;
  border: 1px solid #5A6958;
}

.more-icon {
  position: absolute;
  top: 25px;
  right: 25px;
  height: 40px;
  width: 40px;
  display: none;
  visibility: hidden;
  cursor: pointer;
}

.side-menu {
  background-color: white;
}

.menu-items-container {
  flex-direction: column;
  display: flex !important;
  margin-top: 30px;
  margin-left: 40px;
  text-align: left;
  color: black;
  border: none !important;
  outline: none !important;
}

.menu-item {
  padding-top: 15px;
  padding-bottom: 15px;
  text-decoration: none !important;
  text-transform: uppercase;
  color: black;
  letter-spacing: 1.2px;
}

.menu-item:hover {
  color: #4db0e2;
}

.link {
  text-decoration: none !important;
}

.join-link {
  text-decoration: none !important;
  color: black;
}

.background-image {
  width: 100%;
  height: 55vh;
  background-image: url("https://from-lo-with-love-photos.s3-us-west-1.amazonaws.com/yosemite_tiny.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: none;
  display: relative;
}

.filler {
  height: calc(70vh - 80px);
}

.top-nav {
  display: flex;
  z-index: 1;
  justify-content: space-evenly;
  height: 80px;
  align-items: center;
  font-weight: 400;
  position: relative;
  width: 100%;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.header-link-container {
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  visibility: visible;
}

.top-nav-box-shadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  padding-top: 50px;
}

.logo-container {
  position: absolute;
  width: 100%;
}

.heading-text {
  font-size: large;
  width: 100px;
  font-weight: normal;
}

.white {
  color: white;
}

.header-logo {
  height: 60px;
  width: 76px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.pink-hover:hover {
  color: #ff4940;
  cursor: pointer;
  font-size: 20px;
}

.blue-hover:hover {
  color: #336884;
  font-size: 20px;
  cursor: pointer;
}

.yellow-hover:hover {
  color: #fbb03b;
  cursor: pointer;
  font-size: 20px;
}

.green-hover:hover {
  color: #4db0e2;
  cursor: pointer;
  font-size: 20px;
}

.light-pink-hover:hover {
  color: #f4cace;
  cursor: pointer;
  font-size: 20px;
}

.light-green-hover:hover {
  color: #AEBDA2;
  cursor: pointer;
  font-size: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.subscribe-widget {
  width: 90vw;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(224, 220, 224, 1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(224, 220, 224, 1);
  box-shadow: 1px 1px 3px 0px rgba(224, 220, 224, 1);
  background-color: #f6f6f9;
}

.subscribe-container {
  /* padding-top: 50px; */
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a3442;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cursive-logo {
  padding-top: 10px;
  max-width: 70vw;
  height: 45px;
}

.intro-container {
  margin-top: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: 50px;
  box-sizing: border-box;
}

.intro-content {
  display: flex;
  align-items: flex-start;
  /* margin-bottom: 50px; */
  display: flex;
  justify-content: center;
}

/* maxwidth 1100 => flex-direction: column */

.intro-text-container {
  padding-right: 50px;
  width: 55vw;
}

.from-lo-with-love {
  margin-bottom: 20px;
  font-size: 19px;
  letter-spacing: 0.06em;
  line-height: 1.8em;
  text-align: left;
}

.more-link {
  margin-top: 40px;
  color: #adadad;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  height: inherit;
}

.more-link:hover {
  cursor: pointer;
  color: #4283a5;
}

.more-link span {
  border-color: #adadad;
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
}

.pink-text {
  color: #ff4940;
}

.yellow-text {
  color: #fbb03b;
}

.green-text {
  color: #4db0e2;
}

.blue-text {
  color: #336884;
}

.xlarge {
  font-size: x-large;
}

.xxlarge {
  font-size: xx-large;
}

.intro-image-container {
  height: inherit;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 32vw;
}

.intro-image {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
}

.intro-image-name {
  box-shadow: inset 0 -12px 0 #AEBDA2;
  display: inline-block;
  margin-top: 0;
  margin-left: 7vw;
  margin-right: 7vw;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: normal;
}

.intro-text-paragraph {
  margin-bottom: 20px;
  overflow-wrap: break-word;
  letter-spacing: 0.05em;
  line-height: 1.4em;
  text-align: start;
  font-weight: normal;
}

.breathwork-intro-container {
  padding: 50px;
}

.breathwork-title {
  box-shadow: inset 0 -12px 0 #4db0e252;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 10px;
}

.inhale {
  font-family: cursive;
  color: #adadad;
  margin-bottom: 20px;
}

.breathwork-overview {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.join-me {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  color: #336884;
}

.join-me:hover {
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
}

.breathwork-overview-section {
  width: 55vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.breathwork-overview-text {
  margin-bottom: 20px;
  /* letter-spacing: 0.1em; */
}

.image-container {
  width: 32vw;
}

.breathe-button {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  background-color: #4db0e2;
  color: white;
  border-radius: 5px;
  border: 1px solid #38aae3;
}

.breathe-button:hover {
  cursor: pointer;
  background-color: #38aae3;
}

.about-page {
  position: relative;
}

.about {
  font-size: 60px;
  color: rgba(29, 29, 29, 0.7);
}

.breathwork-subtext {
  text-align: left;
  width: 70vw;
  color: rgba(29, 29, 29, 0.7);
  max-width: 700px
}

.breathwork-overview-title {
  font-size: 40px;
  color: rgba(29, 29, 29, 0.7);
  margin-bottom: 30px;
  /* max-width: 700px;
  width: 70vw;
  text-align: left; */
}

.breathwork-overview-mini {
  font-size: 20px;
  color: rgba(29, 29, 29, 0.7);
}

.about-mini {
  color: rgba(29, 29, 29, 0.7);
}

.about-me-container {
  padding: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-me-photo-container {
  margin: 30px;
  width: 80vw;
  max-width: 800px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.about-me-photo {
  width: 100%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(148, 148, 148, 1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(148, 148, 148, 1);
  box-shadow: 0px 0px 3px 0px rgba(148, 148, 148, 1);
}

.about-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70vw;
  max-width: 800px;
  box-sizing: border-box;
  margin-top: 30px;
}

.about-text-title {
  font-size: 24px;
  letter-spacing: 0.06em;
  line-height: 1.8em;
  text-transform: none;
  /* color: rgba(29, 29, 29, 0.7); */
  text-align: left;
  font-weight: bold;
  padding-bottom: 10px;
}

.im-lo {
  padding-bottom: 30px;
  font-size: 24px;
  letter-spacing: 0.06em;
  line-height: 1.8em;
  text-transform: none;
  /* color: rgba(29, 29, 29, 0.7); */
  text-align: left;
  font-weight: bold;
  font-size: 20px;
}

.quotes {
  /* font-style: italic; */
  padding-bottom: 24px;
  font-size: 18px;
  text-align: left;
  margin-left: 75px;
  color: rgba(29, 29, 29, 0.7);
}

.about-text-paragraph {
  padding-bottom: 20px;
  font-size: 18px;
  letter-spacing: 0.06em;
  line-height: 1.8em;
  text-transform: none;
  /* color: rgba(29, 29, 29, 0.7); */
  text-align: left;
}

.breathwork-facilitator {
  font-size: 18px;
  padding-bottom: 30px;
  font-family: "Rubik";
  color: #808080;
}

.about-icons a i {
  color: #808080;
  cursor: pointer;
}

.about-icons a i:hover {
  color: #b6b6b6;
}

.footer a {
  padding-left: 15px;
  color: white;
}

.five-days-page {
  color: #1a3442;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule-container {
  min-height: 300px;
}

@media screen and (max-width: 770px) {
  .about-me-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-me-photo-container {
    max-width: 100%;
    width: 90vw;
  }

  .about-text-container {
    width: inherit;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-top: 0;
  }

  .header-link-container {
    width: 70vw;
  }

  .intro-content {
    flex-direction: column-reverse;
  }

  .intro-image-container {
    width: 80vw;
    margin-bottom: 30px;
  }

  .intro-text-container {
    width: 80vw;
    padding-right: 0;
  }

  .about-mini {
    padding-top: 10px;
  }
}

@media screen and (max-width: 650px) {
  /* .header-link-container {
    width: 70vw;
  } */

  /* .top-nav {
    justify-content: flex-end;
  } */

  .header-logo {
    height: 70px;
    width: 70px;
    left: 30px;
  }

  .header-link-container {
    margin-right: 50px;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
    padding-top: 50px;
  }

  .about-text-title {
    font-size: 20px;
  }

  .about {
    font-size: 40px;
  }

  .about-text-container {
    width: 90vw;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 650px) {
  .more-icon {
    display: block;
    visibility: visible;
    height: 50px;
    width: 50px;
  }

  .header-link-container {
    visibility: hidden;
    display: none;
  }

  .App-logo {
    height: 25vmin;
    pointer-events: none;
    padding-top: 50px;
  }
}
