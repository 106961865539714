body {
  margin: 0;
  font-family: "Rubik Light", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a3442;
}

.root {
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: local("PlayfairDisplay"),
    url("./assets/fonts/PlayfairDisplay-VariableFont_wgh.ttf")
      format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"),
    url("./assets/fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik Light";
  src: local("Rubik Light"),
    url("./assets/fonts/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "HalimunRegular";
  src: local("HalimunRegular"),
    url("./assets/fonts/Halimun.ttf") format("truetype");
}
