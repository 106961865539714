.slider {
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 5vw;
}

.inactive {
  display: none;
}
.active {
  display: block;
}

.slider-items {
  display: flex;
  align-items: center;
}

.slider-text {
  padding-left: 35px;
  padding-right: 35px;
}

.testimonial {
  letter-spacing: 1.1px;
}

.arrow-container {
  width: 45px;
  padding-bottom: 20px;
}

.arrow {
  cursor: pointer;
  width: 30px;
  display: block;
  visibility: visible;
}

.arrow:hover {
  color: #336884;
}

@media screen and (max-width: 550px) {
  .arrow {
    display: none;
    visibility: hidden;
  }
}
